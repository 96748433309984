import {
  changeViewMode,
  sendUpdateCompany
} from '@/actions/electripure';
import { Button } from '@/common/Button';
import CompanyUpdateForm, { ICompanyUpdateForm } from '@/components/Form/CompanyUpdateForm';
import { ModalMiddle } from '@/components/Modal';
import { ElectripureState } from '@/interfaces/reducers';
import { useEffect, useMemo, useState } from 'react';
import { IoArrowBackOutline, IoArrowForwardOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import ContactInfo from './components/ContactInfo';
import LocationInfo from './components/LocationInfo';
import { ICompany } from './interfaces';

function CompanyDetail() {
  const dispatch = useDispatch();
  const company: ICompany = JSON.parse(
    useSelector((state: ElectripureState) => state.companyDetails)
  );
  const viewMode = useSelector((state: ElectripureState) => state.viewMode);

  const [isActive, setIsActive] = useState<boolean>(company.status === '1');

  useEffect(() => setIsActive(company.status === '1'), [company]);

  const [showModalUpdateCompany, setShowModalUpdateCompany] = useState(false);
  function submitUpdateCompany(data: Partial<ICompanyUpdateForm>) {
    const formDataCompany = new FormData();
    formDataCompany.append('name', data.name ?? '');
    formDataCompany.append('address', data.address ?? '');
    formDataCompany.append('address2', data.address2 ?? '');
    formDataCompany.append('city', data.city ?? '');
    formDataCompany.append('state', data.state ?? '');
    formDataCompany.append('zip', data.zip ?? '');
    if (data.image) {
      formDataCompany.append('image', data.image);
    }

    dispatch(sendUpdateCompany({id: data.id, payload: formDataCompany}))
    setShowModalUpdateCompany(false);
  }

  const sitesLength = useMemo(() => company.sites?.length, [company]);
  const mdpsLength = useMemo(
    () =>
      company.sites?.reduce((acc, site) => {
        return acc + site.mdps.length;
      }, 0),
    [company]
  );

  const changeMode = () => {
    let newMode = '';
    if (viewMode === 'overview') newMode = 'mdp';
    else if (viewMode === 'mdp') newMode = 'overview';

    dispatch(changeViewMode(newMode));
  };

  return (
    <>
      <div className="flex bg-white border w-full h-full p-[40px] gap-x-8">
        <div>
          <div className="rounded border w-[200px] h-auto p-[5px] mb-2">
            <img className="rounded" src={company.cia_image} />
          </div>
        </div>
        <div className="py-[10px] px-[20px] flex flex-col gap-y-2 w-full">
          <div className="flex justify-between items-center">
            <h1 className="text-3xl font-medium w-72">{company.name}</h1>
            {viewMode === 'mdp' && (
              <span
                className="text-blue-100-electripure font-medium cursor-pointer self-start"
                onClick={() => setShowModalUpdateCompany(true)}>
                Edit company info
              </span>
            )}
          </div>
          <div className="flex gap-x-8">
            <LocationInfo
              className="grow"
              location={{
                address: company.address,
                address2: company.address2,
                city: company.city,
                state: company.state,
                zip: company.zip
              }}
            />
            <ContactInfo
            className='grow'
              contact={{
                phone: company.contact_phone,
                email: company.contact_email,
                name: company.contact_name
              }}
            />
            <div className="grow">
              <p>Number of Sites: {sitesLength}</p>
              <p>Number of MDPs: {mdpsLength}</p>
            </div>
          </div>
          <div className="self-end">
            <Button
              className="flex items-center gap-x-3 px-5 py-3 rounded-md"
              onClick={changeMode}>
              {viewMode === 'mdp' ? (
                <>
                  <span className="font-medium">MDP Info</span>
                  <IoArrowForwardOutline />
                </>
              ) : (
                <>
                  <span className="font-medium">Overview Mode</span>
                  <IoArrowBackOutline />
                </>
              )}
            </Button>
          </div>
        </div>
      </div>
      <ModalMiddle
        show={showModalUpdateCompany}
        onClose={() => {
          setShowModalUpdateCompany(false);
        }}>
        <CompanyUpdateForm
          company={{
            ...company,
            url_image: company.cia_image,
            id_image: company.id_image,
            active: isActive
          }}
          onSubmit={submitUpdateCompany}
          setIsActive={setIsActive}
        />
      </ModalMiddle>
    </>
  );
}

export default CompanyDetail;
